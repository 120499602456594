<script lang="ts">
	import { renderAsMarkdown, renderAsHtml } from '$lib/stores';
	import Quote from './Quote.svelte';

	export let quote: string;
	export let id: string;
</script>

<Quote {id}>
	{#if $renderAsMarkdown}
		<tt>### {quote}</tt>
		<br />
		<br />
	{:else if $renderAsHtml}
		<tt>&lt;h3&gt;{quote}&lt;/h3&gt;</tt>
		<br />
	{:else}
		<h3 class="text-lg font-bold">{quote}</h3>
	{/if}
</Quote>
