<script lang="ts">
	import type { Sources } from '$lib/types';
	import { selectedSources } from '$lib/stores';
	import Quotes from '$lib/components/Quotes.svelte';

	export let data: Sources;

	$: selectedData = data.sources.filter((s) => $selectedSources.includes(s.key));
</script>

<Quotes sources={selectedData} />
