<script lang="ts">
	import { useBoldText, useItalicText, useLinks } from '$lib/stores';
	import { selectText } from '$lib/utils';
	import Quote from './Quote.svelte';

	export let quote: string;
	export let isNumberedList: boolean = false;
	export let isBulletedList: boolean = false;
	export let id: string;

	const copy = (content: string) => {
		navigator.clipboard.writeText(content || '');
	};

	$: renderAsBold = $useBoldText && Math.random() > 0.8;
	$: renderAsItalic = $useItalicText && Math.random() > 0.8;
	$: renderAsLink = $useLinks && Math.random() > 0.9;

	$: markup = quote;
	$: {
		if (renderAsBold) markup = `<strong>${markup}</strong>`;
		if (renderAsItalic) markup = `<emphasis>${markup}</emphasis>`;
		if (renderAsLink) markup = `<a href="https://loremquotesum.com">${markup}</a>`;
		if (isNumberedList || isBulletedList) {
			markup = `<li>${markup}</li>`;
		} else {
			markup = `<p>${markup}</p>`;
		}
	}
</script>

<Quote {id}>
	<tt>
		{markup}
		<br />
	</tt>
</Quote>
