<script lang="ts">
	import { useBoldText, useItalicText, useLinks } from '$lib/stores';
	import { selectText } from '$lib/utils';

	export let quote: string;
	export let isNumberedList: boolean = false;
	export let isBulletedList: boolean = false;
	export let id: string;

	$: renderAsBold = $useBoldText && Math.random() > 0.8;
	$: renderAsItalic = $useItalicText && Math.random() > 0.8;
	$: renderAsLink = $useLinks && Math.random() > 0.9;

	$: tag = isNumberedList || isBulletedList ? 'li' : 'p';
	$: markup = '';
	$: {
		markup = quote;
		if (renderAsBold) markup = `<b>${markup}</b>`;
		if (renderAsItalic) markup = `<i>${markup}</i>`;
	}
</script>

<svelte:element
	this={tag}
	role={renderAsLink ? 'link' : ''}
	tabindex="-1"
	{id}
	on:click|preventDefault|once={() => selectText(id)}
	class:py-1={!isNumberedList && !isBulletedList}
>
	{#if renderAsLink}
		<a href="https://loremquotesum.com/" class="underline">{@html markup}</a>
	{:else}
		{@html markup}
	{/if}
</svelte:element>
