<script lang="ts">
	import { selectText } from '$lib/utils';

	export let id: string;
</script>

<span
	{id}
	role="button"
	tabindex="-1"
	on:click|preventDefault|once={() => selectText(id)}
	on:keypress|preventDefault|once={() => selectText(id)}
>
	<slot />
</span>
