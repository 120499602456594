<script lang="ts">
	import type { Source } from '$lib/types';

	import { numCharacters, numParagraphs, renderAsHtml, renderAsMarkdown } from '$lib/stores';
	import QuoteGroup from './QuoteGroup.svelte';
	import { copyQuotes } from '$lib/utils';

	const arrayContentsLength = (array: string[]) => {
		return array.reduce((acc, curr) => acc + curr.length, 0);
	};

	export let sources: Source[];

	$: merged = sources
		.map((s) => s.quotes)
		.flat()
		.sort((a, b) => Math.random() - 0.5);

	$: filtered = merged.reduce((acc, curr) => {
		if (arrayContentsLength(acc) < $numCharacters) {
			if (arrayContentsLength(acc) + curr.length <= $numCharacters) {
				acc.push(curr);
			} else {
				const shorterCurr = curr.slice(0, $numCharacters - arrayContentsLength(acc) - 3);
				acc.push(shorterCurr + '...');
			}
		}
		return acc;
	}, [] as string[]);

	$: quotesPerParagraph = Math.max(Math.floor(filtered.length / $numParagraphs), 1);

	$: quoteGroups = Array($numParagraphs)
		.fill(0)
		.map((_, i) => {
			return i === $numParagraphs - 1
				? filtered.slice(i * quotesPerParagraph)
				: filtered.slice(i * quotesPerParagraph, (i + 1) * quotesPerParagraph);
		});

	// $: {
	// console.log('Merged', merged);
	// console.log('Filtered', filtered);
	// console.log('Quote groups', quoteGroups);
	// }

	const copy = (useRichText: boolean) => {
		copyQuotes('quotes-wrapper');
	};
</script>

<div class="quotes">
	<div class="header-wrapper flex flex-row justify-between pl-2">
		<h2 class="text-2xl font-bold">
			{filtered.length} Quotes ({arrayContentsLength(filtered)} characters)
		</h2>
		<button
			class="btn btn-sm variant-filled-primary hover:scale-105 active:scale-95"
			on:click={() => copy(!$renderAsHtml && !renderAsMarkdown)}>Copy All</button
		>
	</div>
	<div id="quotes-wrapper">
		{#if sources && sources.length > 0}
			{#each quoteGroups as group, index}
				<QuoteGroup quotes={group} idx={index} numParagraphs={$numParagraphs} />
			{/each}
		{:else}
			<div class="nothing-selected pl-2 pt-2">
				<p>Select some quote sources</p>
				<p class="treasure-hunt">
					Jed's Treasure Hunt Clue #3: To find the treasure, enter the secret room, turn left and
					walk to the end, then turn right and look for a <a
						href="https://jedfonner.com/media/appian-treasure-hunt-clue-3-019d5b0f.png"
						target="_blank"
						rel="noreferrer">sideways cabinet with three doors</a
					>. Find the key hidden nearby and open the long door.
				</p>
			</div>
		{/if}
	</div>
</div>

<style>
	.quotes {
		margin: 0 1rem;
	}
	.quotes {
		height: 100%;
		position: relative;
	}

	.treasure-hunt {
		position: absolute;
		left: 0.25rem;
		bottom: 0.5rem;
		color: rgb(var(--color-primary-400));
		font-size: small;
	}
	.treasure-hunt a {
		text-decoration: underline;
	}
</style>
