<script lang="ts">
	import { useBoldText, useItalicText, useLinks } from '$lib/stores';
	import Quote from './Quote.svelte';

	export let quote: string;
	export let isNumberedList: boolean = false;
	export let isBulletedList: boolean = false;
	export let id: string;

	$: renderAsBold = $useBoldText && Math.random() > 0.8;
	$: renderAsItalic = $useItalicText && Math.random() > 0.8;
	$: renderAsLink = $useLinks && Math.random() > 0.9;

	$: markdown = '';
	$: {
		markdown = quote;
		if (renderAsBold) markdown = `**${markdown}**`;
		if (renderAsItalic) markdown = `_${markdown}_`;
		if (renderAsLink) markdown = `[${markdown}](https://loremquotesum.com/)`;
		if (isNumberedList) markdown = `1. ${markdown}`;
		if (isBulletedList) markdown = ` - ${markdown}`;
	}
</script>

<tt>
	<Quote {id}>{markdown}</Quote>
	<br />
	<br />
</tt>
