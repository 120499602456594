export interface Sources {
	sources: Source[];
}
/* if updating this, also update destructured values in loadData.ts */
export interface Source {
	key: string;
	label: string;
	image: string;
	srcset: string;
	quotes: string[];
}

export interface Resolved {
	default: Source;
}

export enum RENDER_TYPES {
	'Text',
	'Markdown',
	'HTML'
}

export enum LIST_TYPES {
	'Numbered',
	'Bulleted'
}
