import { copyAsMarkup } from '$lib/stores';

export const copyQuotes = (idSelector: string) => {
	let treatAsMarkup = true;
	copyAsMarkup.subscribe((value) => {
		treatAsMarkup = value;
	});

	// From https://stackoverflow.com/questions/23934656/how-can-i-copy-rich-text-contents-to-the-clipboard-with-javascript
	const content = treatAsMarkup
		? document.getElementById(idSelector)?.innerHTML
		: document.getElementById(idSelector)?.innerText;
	function listener(e: any) {
		e.clipboardData.setData('text/html', content);
		e.clipboardData.setData('text/plain', content);
		e.preventDefault();
	}
	document.addEventListener('copy', listener);
	document.execCommand('copy');
	document.removeEventListener('copy', listener);
};

export const selectText = (id: string) => {
	const node = document.getElementById(id);
	if (node) {
		const selection = window.getSelection();
		const range = document.createRange();
		range.selectNodeContents(node);
		selection?.removeAllRanges();
		selection?.addRange(range);
	}
};

export const deselectText = (id: string) => {
	const selection = window.getSelection();
	selection?.removeAllRanges();
};
