<script lang="ts">
	import { LIST_TYPES, RENDER_TYPES } from '$lib/types.d';

	export let renderType: RENDER_TYPES;
	export let listType: LIST_TYPES | null = null;
</script>

{#if renderType === RENDER_TYPES.Markdown}
	<slot />
{:else if renderType === RENDER_TYPES.HTML}
	{#if listType === LIST_TYPES.Numbered}
		<tt>&lt;ol&gt;<br /><slot />&lt;/ol&gt;</tt><br />
	{:else if listType === LIST_TYPES.Bulleted}
		<tt>&lt;ul&gt;<slot />&lt;/ul&gt;</tt><br />
	{:else}
		<slot />
	{/if}
{:else if renderType === RENDER_TYPES.Text}
	{#if listType === LIST_TYPES.Numbered}
		<ul class="list-disc list-inside"><slot /></ul>
	{:else if listType === LIST_TYPES.Bulleted}
		<ol class="list-decimal list-inside"><slot /></ol>
	{:else}
		<slot />
	{/if}
{/if}
