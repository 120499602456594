<script lang="ts">
	import QuoteAsText from './QuoteAsText.svelte';
	import QuoteAsMarkdown from './QuoteAsMarkdown.svelte';
	import QuoteAsHtml from './QuoteAsHTML.svelte';
	import {
		useHeaders,
		renderAsMarkdown,
		renderAsHtml,
		useBulletedLists,
		useNumberedLists
	} from '$lib/stores';
	import { copyQuotes } from '$lib/utils';
	import QuoteHeader from './QuoteHeader.svelte';
	import QuoteListWrapper from './QuoteListWrapper.svelte';
	import { LIST_TYPES, RENDER_TYPES } from '$lib/types.d';

	export let quotes: string[];
	export let numParagraphs: number;
	export let idx: number;

	// Use svelte-based hover instead of css to allow copying the whole block without
	// from the top also copying the copy button in each quote group
	let isHovering = false;

	$: renderAsNumberedList =
		$useNumberedLists && ((numParagraphs <= 3 && idx === 0) || idx % 4 === 1);
	$: renderAsBulletedList =
		$useBulletedLists && ((numParagraphs <= 3 && idx === 1) || idx % 4 === 3);

	$: renderType = $renderAsHtml
		? RENDER_TYPES.HTML
		: $renderAsMarkdown
			? RENDER_TYPES.Markdown
			: RENDER_TYPES.Text;

	$: listType = renderAsNumberedList
		? LIST_TYPES.Numbered
		: renderAsBulletedList
			? LIST_TYPES.Bulleted
			: null;

	$: firstItem = quotes[0];
	$: remaining = quotes.slice(1);

	$: quoteComponent = $renderAsMarkdown
		? QuoteAsMarkdown
		: $renderAsHtml
			? QuoteAsHtml
			: QuoteAsText;

	const copy = () => {
		copyQuotes(`quote-group-${idx}`);
	};
</script>

<div
	class="quote-group-wrapper my-1 rounded-lg p-2 hover:bg-primary-100-800-token"
	on:mouseenter={() => (isHovering = true)}
	on:mouseleave={() => (isHovering = false)}
	role="region"
>
	<div class="quote-group" id="quote-group-{idx}">
		{#if $useHeaders}
			<QuoteHeader quote={firstItem} id="quote-{idx}-header" />
		{/if}
		<QuoteListWrapper {renderType} {listType}>
			{#each $useHeaders ? remaining : quotes as quote, index}
				{#key quote}
					<svelte:component
						this={quoteComponent}
						{quote}
						isBulletedList={renderAsBulletedList}
						isNumberedList={renderAsNumberedList}
						id="quote-{idx}-{index}"
					/>
				{/key}
			{/each}
		</QuoteListWrapper>
	</div>
	{#if isHovering}
		<div class="copy hover:scale-105 active:scale-95">
			<button class="variant-filled-primary btn btn-sm" on:click={copy}>Copy</button>
		</div>
	{/if}
</div>

<style>
	.quote-group-wrapper {
		position: relative;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
	}
	.copy {
		padding: 1rem;
		position: absolute;
		right: 0;
	}
	/* .copy > button { */
	/* filter: drop-shadow(0px 0px 10px black); */
	/* } */
</style>
